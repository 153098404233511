// features/counter/counterSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: '',
};

const reportSlices = createSlice({
  name: "report",
  initialState,
  reducers: {
    reportSidebar: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { reportSidebar } = reportSlices.actions;
export default reportSlices.reducer;
