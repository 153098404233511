/* eslint-disable no-prototype-builtins */
import axios from "axios";
import toast from "react-hot-toast";
import { api } from "shared-components/global-api/GlobalApi";

//fungsi delete sementara
export const handleTemporaryDelete = (id, data, setdata) => {
  const updatedData = data.filter((item) => item.id !== id);
  setdata(updatedData);
  toast.success("Berhasil menghapus data!", {
    duration: 2000,
    position: "top-center",
  });
};

export const getData = async (apiUrl, config) => {
  try {
    const response = await axios.get(`${api}${apiUrl}`, config);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return error?.response?.data;
  }
};

export const getDataNew = async (apiUrl, config) => {
  try {
    const response = await axios.get(`${api}${apiUrl}`, config);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    if (error.response) {
      return error.response.data;
    } else {
      return [];
    }
  }
};


export const saveData = async (apiUrl, body, config) => {
  try {
    const response = await axios.post(`${api}${apiUrl}`, body, config);
    return response.data;
  } catch (error) {
    console.error("Error save data:", error);
    return error?.response?.data;
  }
};

export const updateData = async (apiUrl, body, config) => {
  try {
    const response = await axios.put(`${api}${apiUrl}`, body, config);
    return response.data;
  } catch (error) {
    console.error("Error update data:", error);
    // return error?.response?.data;
  }
};

export const deleteData = async (apiUrl, config, id, data, setdata) => {
  try {
    const response = await axios.delete(`${api}${apiUrl}`, config);
    // response?.data?.status === 200 && handleTemporaryDelete(id, data, setdata)
    return response.data;
  } catch (error) {
    console.error("Error delete data:", error);
  }
};
