/* eslint-disable no-useless-escape */
import moment from "moment/moment";

//fungsi membatasi jumlah karakter yang tampil
//konversi KB ke MB
export const licenseNumber = "X7K8PMN3J2Q9Z5A8L4TPYF7B1";

export const characterLimit = (text, limitText) => {
  const truncatedText =
    text?.length > limitText ? `${text?.slice(0, limitText)}...` : text;
  return truncatedText;
};

//konversi KB ke MB
export const convertKBtoMB = (kb, numberBehindComa) => {
  return (kb / 1024).toFixed(numberBehindComa);
};
export const bytesToMB = (bytes) => (bytes / (1024 * 1024)).toFixed(2);

export const formatFileSize = (size) => {
  try {
    if (typeof size !== "number" || size < 0) throw new Error("Invalid size");
    if (size < 1024) return `${size} B`;
    const i = Math.floor(Math.log(size) / Math.log(1024));
    const sizes = ["B", "KB", "MB", "GB", "TB"];
    return `${(size / Math.pow(1024, i)).toFixed(2)} ${sizes[i]}`;
  } catch {
    return "0 B";
  }
};

export const getCurrentDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Tambah 1 karena bulan dimulai dari 0
  const day = String(today.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

//fungsi menampilkan image biner
export const displayBinaryImage = (image) => {
  const base64Format = "data:image/jpeg;base64,";
  return base64Format + image;
};

//fungsi untuk menampilkan date dari api
export const formatDisplayDate = (data) => {
  const inputDate = data;
  const date = moment.utc(inputDate);
  return date.format("D MMM YYYY");
};

export const formatDisplayDate2 = (data) => {
  const inputDate = data;
  const date = moment.utc(inputDate);
  return date.format("D-MM-YYYY");
};
export const formatTimestamp = (data) => {
  const inputDate = data;
  const date = moment.utc(inputDate);
  return date.format("DD MMM yyyy HH:mm");
};

export const formatDisplayDate3 = (data) => {
  const inputDate = data;
  const date = moment.utc(inputDate);
  return date.format("YYYY-MM-DD HH:mm:ss");
};

//fungsi untuk input date form
export const formatDateInput = (field) => {
  return moment(field).format("YYYY-MM-DD");
};

export const formatDateTimeInput = (field) => {
  return moment(field).format("YYYY-MM-DD HH:mm:ss");
};

//fungsi logout + menghapus data di local storage
export const handleLogout = () => {
  localStorage.clear();
  window.location.href = "/login";
};

//fungsi untuk menghilangkan kutip
export const valueWithoutQuotes = (value) => {
  return value.replace(/['"]+/g, "");
};

export const handleArrayValue = (value) => {
  return value.replace(/[\[\]"]/g, "");
};

//fungsi untuk convert file ke base64
export const fileToBase64 = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsDataURL(file);
  });
};

export const isAllDataNotNull = async (body) => {
  for (const key in body) {
    if (body[key] === null || body[key] === undefined || body[key] === "") {
      return false;
    }
  }

  return true;
};

export const base64ToFile = (base64String, filename) => {
  const arr = base64String.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};
