// store.js
import { configureStore } from '@reduxjs/toolkit';
import realtimeSlice from './slice/realtimeSlice';
import ipCamSlice from './slice/ipCamSlice';
import playbackSlice from './slice/playbackSlice';
import reportSlices from './slice/reportSlices';

const store = configureStore({
  reducer: {
    realtime: realtimeSlice, // Add your reducers here
    ipCam: ipCamSlice, // Add your reducers here
    playback: playbackSlice,
    report: reportSlices,
  },
});

export default store;
