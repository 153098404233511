// features/counter/counterSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: 0,
};

const playbackSlice = createSlice({
  name: "playback",
  initialState,
  reducers: {
    playbackSidebar: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { playbackSidebar } = playbackSlice.actions;
export default playbackSlice.reducer;
