// features/ipCam/ipCamSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { displayBinaryImage } from 'shared-components/global-function/Helper';
import { getData } from "shared-components/global-crud/GlobalCrud";

// Initial state
const initialState = {
  ipCamList: {
    data: [],
    isLoading: false,
    error: null,
  },
};

// Thunk action for fetching IP Cam list
export const getIpCamList = createAsyncThunk(
  'ipCam/getIpCamList',
  async ({ organizationId, apis, config }, { rejectWithValue }) => {
    try {
      const url = `${process.env.REACT_APP_API_URL_API_FR}/api/register-ipcam/get-all/${organizationId}`;
      const result = await axios.get(url, config);
      
      if (result?.status === 200) {
          const thumbnailPromises = result?.data?.data.map((val) =>
            axios
          .get(`${process.env.REACT_APP_API_URL_API_FR}/api/screenshot/${val.id}`, config)
          .then((response) => ({
              ...val,
              thumbnail: displayBinaryImage(response.data),
            }))
        );
        
        // console.log(result?.data?.data, 'resultresult')
        const dataWithThumbnails = await Promise.all(thumbnailPromises);
        return dataWithThumbnails;
      } else {
        throw new Error('Failed to fetch IP Cam List');
      }
    } catch (error) {
      return rejectWithValue(error.message || 'Unknown error occurred');
    }
  }
);

// Slice definition
const ipCamSlice = createSlice({
  name: 'ipCam',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getIpCamList.pending, (state) => {
        state.ipCamList.isLoading = true;
        state.ipCamList.error = null;
      })
      .addCase(getIpCamList.fulfilled, (state, action) => {
        // console.log(state.ipCamList, 'state.ipCamList')
        state.ipCamList.isLoading = false;
        state.ipCamList.data = action.payload;
      })
      .addCase(getIpCamList.rejected, (state, action) => {
        state.ipCamList.isLoading = false;
        state.ipCamList.error = action.payload;
      });
  },
});

export default ipCamSlice.reducer;
